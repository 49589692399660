import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_742_1339)'>
          <path
            d='M18.1235 13.3056V0.283951C18.1235 0.126543 17.9969 0 17.8395 0H0.283951C0.126543 0 0 0.126543 0 0.283951V13.3056C0 13.463 0.126543 13.5895 0.283951 13.5895H17.8395C17.9969 13.5895 18.1235 13.463 18.1235 13.3056Z'
            fill='#96633C'/>
          <path
            d='M22.361 24.713L39.8333 7.24074C39.9382 7.1358 39.9999 6.99074 39.9999 6.83951V0.283951C39.9999 0.126543 39.8734 0 39.716 0H22.1604C22.003 0 21.8765 0.126543 21.8765 0.283951V24.5123C21.8765 24.7654 22.182 24.892 22.3579 24.713'
            fill='#383D2F'/>
          <path
            d='M21.8796 30.7408V39.7161C21.8796 39.8735 22.0062 40 22.1636 40H39.7191C39.8766 40 40.0031 39.8735 40.0031 39.7161V13.0679C40.0031 12.8148 39.6975 12.6883 39.5216 12.8673L22.0494 30.3395C21.9445 30.4445 21.8827 30.5895 21.8827 30.7408'
            fill='#5D6144'/>
          <path
            d='M17.8395 17.3456H0.283951C0.126543 17.3456 0 17.4722 0 17.6265V39.716C0 39.8734 0.126543 39.9999 0.283951 39.9999H17.8395C17.9969 39.9999 18.1235 39.8734 18.1235 39.716V17.6265C18.1235 17.4691 17.9969 17.3425 17.8395 17.3425'
            fill='#CAB181'/>
        </g>
        <defs>
          <clipPath id='clip0_742_1339'>
            <rect width='40' height='40' fill='white'/>
          </clipPath>
        </defs>
      </svg>
    )
  }
}

const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 19L13 13M1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788C14.8189 9.82951 15 8.91925 15 8C15 7.08075 14.8189 6.1705 14.4672 5.32122C14.1154 4.47194 13.5998 3.70026 12.9497 3.05025C12.2997 2.40024 11.5281 1.88463 10.6788 1.53284C9.82951 1.18106 8.91925 1 8 1C7.08075 1 6.1705 1.18106 5.32122 1.53284C4.47194 1.88463 3.70026 2.40024 3.05025 3.05025C2.40024 3.70026 1.88463 4.47194 1.53284 5.32122C1.18106 6.1705 1 7.08075 1 8Z" stroke="#EFEAE0" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
)

const CreateLogo = (props) => (
  <svg width='217' height='54' viewBox='0 0 217 54' fill={ props.color } xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.4542 54C22.3328 54 17.7601 52.8519 13.7362 50.5556C9.7122 48.2346 6.57839 45.0247 4.33473 40.9259C2.09107 36.8272 0.969238 32.1852 0.969238 27C0.969238 21.8148 2.09107 17.1728 4.33473 13.0741C6.57839 8.97531 9.7122 5.77778 13.7362 3.48148C17.7601 1.16049 22.3328 0 27.4542 0C32.2342 0 36.4166 0.938272 40.0016 2.81481C43.5866 4.69136 46.4034 7.17284 48.4519 10.2593C50.5005 13.321 51.7442 16.6667 52.1832 20.2963H47.2081C46.5985 17.0617 45.4157 14.2593 43.6598 11.8889C41.9282 9.51852 39.6846 7.69136 36.9288 6.40741C34.1974 5.12346 31.0636 4.48148 27.5273 4.48148C23.3327 4.48148 19.6136 5.4321 16.37 7.33333C13.1265 9.23457 10.6023 11.8889 8.79766 15.2963C7.01737 18.7037 6.12722 22.6049 6.12722 27C6.12722 31.3951 7.01737 35.2963 8.79766 38.7037C10.6023 42.1111 13.1265 44.7654 16.37 46.6667C19.6136 48.5679 23.3327 49.5185 27.5273 49.5185C31.0636 49.5185 34.1974 48.8765 36.9288 47.5926C39.6846 46.3086 41.9282 44.4815 43.6598 42.1111C45.4157 39.7407 46.5985 36.9383 47.2081 33.7037H52.1832C51.7442 37.3333 50.5005 40.6914 48.4519 43.7778C46.4034 46.8395 43.5866 49.3086 40.0016 51.1852C36.4166 53.0617 32.2342 54 27.4542 54Z'
      fill={ props.color }/>
    <path
      d='M58.2973 52.7778V15.3704H61.553C61.6262 17.642 61.7481 19.1975 61.9189 20.037C62.0164 20.4568 62.1262 20.7407 62.2481 20.8889C62.37 21.037 62.5164 21.1111 62.6871 21.1111C62.8578 21.1111 63.0041 21.0617 63.126 20.963C63.248 20.8395 63.3943 20.5926 63.565 20.2222C64.4674 18.4938 65.7355 17.1728 67.3695 16.2593C69.0278 15.321 71.0276 14.8519 73.3688 14.8519C74.7833 14.8519 75.893 14.9753 76.6978 15.2222V19.4444C75.5759 19.1481 74.32 19 72.9299 19C71.0764 19 69.4912 19.3457 68.1743 20.037C66.8817 20.7284 65.8331 21.7901 65.0283 23.2222C64.2723 24.5309 63.7357 25.9753 63.4187 27.5556C63.1017 29.1358 62.9431 31.0988 62.9431 33.4444V52.7778H58.2973Z'
      fill={ props.color }/>
    <path
      d='M114.674 32.963C114.674 34.1481 114.638 35 114.564 35.5185H82.2631C82.3363 38.3827 82.9825 40.9012 84.2019 43.0741C85.4213 45.2469 87.104 46.9259 89.2502 48.1111C91.3963 49.2963 93.8594 49.8889 96.6396 49.8889C98.7857 49.8889 100.737 49.5432 102.493 48.8519C104.273 48.1605 105.748 47.1728 106.919 45.8889C108.114 44.6049 108.931 43.0988 109.37 41.3704H114.126C113.565 43.9383 112.479 46.1728 110.87 48.0741C109.26 49.9506 107.236 51.4074 104.797 52.4444C102.358 53.4568 99.6393 53.963 96.6396 53.963C92.8595 53.963 89.5184 53.1358 86.6163 51.4815C83.7385 49.8272 81.4949 47.4938 79.8853 44.4815C78.2757 41.4691 77.4709 38 77.4709 34.0741C77.4709 30.1728 78.3001 26.716 79.9585 23.7037C81.6412 20.6914 83.9215 18.358 86.7992 16.7037C89.7013 15.0494 92.9083 14.2222 96.4201 14.2222C100.078 14.2222 103.285 15.0123 106.041 16.5926C108.797 18.1728 110.919 20.3827 112.406 23.2222C113.918 26.037 114.674 29.284 114.674 32.963ZM96.4567 18.1852C93.8716 18.1852 91.5426 18.7407 89.4696 19.8519C87.4211 20.963 85.7749 22.5309 84.5312 24.5556C83.2874 26.5802 82.5557 28.9259 82.3363 31.5926H110.102C110.053 28.9506 109.443 26.6173 108.272 24.5926C107.126 22.5679 105.529 21 103.48 19.8889C101.456 18.7531 99.1149 18.1852 96.4567 18.1852Z'
      fill={ props.color }/>
    <path
      d='M135.531 14.1852C140.433 14.1852 144.152 15.5432 146.688 18.2593C149.224 20.9753 150.492 24.8642 150.492 29.9259V52.7778H147.127C147.029 50.4074 146.883 48.716 146.688 47.7037C146.615 47.358 146.517 47.1111 146.395 46.963C146.298 46.8148 146.139 46.7407 145.92 46.7407C145.7 46.7407 145.505 46.8148 145.334 46.963C145.188 47.0864 144.981 47.358 144.713 47.7778C143.322 49.8765 141.603 51.4321 139.555 52.4444C137.506 53.4568 135.067 53.963 132.238 53.963C129.556 53.963 127.178 53.4938 125.105 52.5556C123.032 51.5926 121.422 50.2222 120.276 48.4444C119.13 46.642 118.557 44.5432 118.557 42.1481C118.557 38.716 119.74 36.0988 122.105 34.2963C124.471 32.4691 128.044 31.3951 132.824 31.0741C139.555 30.6296 143.92 30.0741 145.92 29.4074C145.871 25.7778 144.956 23.0123 143.176 21.1111C141.396 19.1852 138.811 18.2222 135.421 18.2222C132.543 18.2222 130.141 18.9259 128.214 20.3333C126.312 21.716 125.068 23.6543 124.483 26.1481H119.764C120.252 23.7284 121.203 21.6173 122.617 19.8148C124.032 18.0123 125.837 16.6296 128.031 15.6667C130.226 14.679 132.726 14.1852 135.531 14.1852ZM144.274 42.8148C145.371 40.0988 145.92 37.0617 145.92 33.7037V33.037C144.92 33.3827 143.396 33.6914 141.347 33.963C139.299 34.2346 136.543 34.5062 133.08 34.7778C129.617 35.0741 127.105 35.8025 125.544 36.963C124.007 38.1235 123.239 39.8395 123.239 42.1111C123.239 44.5802 124.129 46.5185 125.91 47.9259C127.69 49.3333 130.068 50.037 133.043 50.037C135.531 50.037 137.762 49.4938 139.737 48.4074C141.737 47.321 143.249 45.4568 144.274 42.8148Z'
      fill={ props.color }/>
    <path
      d='M172.154 53.1481C169.642 53.1481 167.593 52.7284 166.008 51.8889C164.447 51.0247 163.289 49.6667 162.533 47.8148C161.777 45.9383 161.399 43.4938 161.399 40.4815V19.3333H153.79V15.4074H158.692C159.789 15.4074 160.582 15.1975 161.069 14.7778C161.582 14.358 161.923 13.6296 162.094 12.5926C162.24 11.8025 162.374 10.5062 162.496 8.7037C162.642 6.90123 162.74 5.39506 162.789 4.18518H166.044V15.4074H176.982V19.3333H166.044V40.1852C166.044 42.3827 166.276 44.1358 166.74 45.4444C167.203 46.7284 167.935 47.6667 168.934 48.2593C169.959 48.8519 171.349 49.1481 173.105 49.1481C174.47 49.1481 175.958 48.9506 177.568 48.5556V52.3704C176.958 52.5926 176.141 52.7778 175.117 52.9259C174.117 53.0741 173.129 53.1481 172.154 53.1481Z'
      fill={ props.color }/>
    <path
      d='M216.056 32.963C216.056 34.1481 216.02 35 215.947 35.5185H183.645C183.718 38.3827 184.365 40.9012 185.584 43.0741C186.803 45.2469 188.486 46.9259 190.632 48.1111C192.778 49.2963 195.241 49.8889 198.022 49.8889C200.168 49.8889 202.119 49.5432 203.875 48.8519C205.655 48.1605 207.13 47.1728 208.301 45.8889C209.496 44.6049 210.313 43.0988 210.752 41.3704H215.508C214.947 43.9383 213.861 46.1728 212.252 48.0741C210.642 49.9506 208.618 51.4074 206.179 52.4444C203.741 53.4568 201.021 53.963 198.022 53.963C194.242 53.963 190.9 53.1358 187.998 51.4815C185.121 49.8272 182.877 47.4938 181.267 44.4815C179.658 41.4691 178.853 38 178.853 34.0741C178.853 30.1728 179.682 26.716 181.34 23.7037C183.023 20.6914 185.303 18.358 188.181 16.7037C191.083 15.0494 194.29 14.2222 197.802 14.2222C201.46 14.2222 204.667 15.0123 207.423 16.5926C210.179 18.1728 212.301 20.3827 213.788 23.2222C215.3 26.037 216.056 29.284 216.056 32.963ZM197.839 18.1852C195.254 18.1852 192.925 18.7407 190.852 19.8519C188.803 20.963 187.157 22.5309 185.913 24.5556C184.669 26.5802 183.938 28.9259 183.718 31.5926H211.484C211.435 28.9506 210.825 26.6173 209.655 24.5926C208.508 22.5679 206.911 21 204.862 19.8889C202.838 18.7531 200.497 18.1852 197.839 18.1852Z'
      fill={ props.color }/>
  </svg>
)

const HouseLogo = (props) => (
  <svg width='38' height='50' viewBox='0 0 38 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.8935 0V8.08366H0V50H4.40028C4.77928 41.6542 10.3962 35.0301 17.2709 35.0301C24.1469 35.0301 29.7639 41.6542 30.1416 50H34.0562V28.4431H37.4248V0H21.8935ZM11.6745 27.7584H5.84305V20.055C5.84305 18.0726 7.14836 16.4667 8.75816 16.4667C10.368 16.4667 11.6733 18.0739 11.6733 20.055V27.7584H11.6745Z'
      fill={ props.color || '#21242F' }/>
  </svg>
)

const Icon = (props) => (
  <svg width='22' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg' { ...props }>
    <path
      d='M9.26843 0V3.22384H0.537109V19.9405H2.29198C2.44313 16.6121 4.68322 13.9703 7.42491 13.9703C10.1671 13.9703 12.4072 16.6121 12.5578 19.9405H14.119V11.3434H15.4625V0H9.26843ZM5.19303 11.0703H2.86737V7.99812C2.86737 7.20753 3.38794 6.56706 4.02994 6.56706C4.67194 6.56706 5.19251 7.20804 5.19251 7.99812V11.0703H5.19303Z'
      fill={ props.color || 'white' }/>
  </svg>
)

const DownArrow = () => (
  <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5 4.94872L0 0L10 8.74228e-07L5 4.94872Z' fill='white'/>
  </svg>
)

const ThinDownArrow = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1V15M5 15L9 11M5 15L1 11" stroke="#383D2F" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const Download = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 8L9 13M9 13L14 8M9 13V1" stroke="#383D2F" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

class Arrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M14.8542 8.86329L18.308 5.44704H0.296875V4.72706H18.308L14.8542 1.31082L15.3691 0.801514L19.7017 5.0871L15.3691 9.37268L14.8542 8.86329Z'
              fill={ color }/>
      </svg>
    )
  }
}

const LongArrow = (props) => (
  <svg width='70' height='28' viewBox='0 0 70 28' fill={ props.color } xmlns='http://www.w3.org/2000/svg'>
    <path d='M4 14H66.5M66.5 14L61.3571 19M66.5 14L61.3571 9' stroke={ props.color } stroke-width='0.8'/>
  </svg>
)

const ShortArrow = (props) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill={ props.color } xmlns='http://www.w3.org/2000/svg'>
    <path d='M5 14H23M23 14L17.8571 19M23 14L17.8571 9' stroke={ props.color } stroke-width='0.6'/>
  </svg>
)

const ShortArrowLeft = (props) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M23 14H5M5 14L10.1429 19M5 14L10.1429 9' stroke={ props.color } stroke-width='0.6'/>
  </svg>
)

const LandSize = (props) => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20.8819 1.23328H0.500244V21.6149H20.8819V1.23328Z' stroke={ props.color } stroke-width='0.658748' stroke-miterlimit='10'/>
    <path d='M9.15621 12.959H0.500244V21.6149H9.15621V12.959Z' stroke={ props.color } stroke-width='0.658748' stroke-miterlimit='10'/>
    <path d='M10.6858 6.75342H15.3497V11.4174' stroke={ props.color } stroke-width='0.658748' stroke-miterlimit='10'/>
    <path d='M9.15454 12.959L15.2677 6.83264' stroke={ props.color } stroke-width='0.658748' stroke-miterlimit='10'/>
  </svg>
)

const AirPort = (props) => (
  <svg width='36' height='21' viewBox='0 0 36 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.73915 4.98184L7.37921 10.4418L5.55914 13.0785H8.02087L10.2025 12.4368H30.7826C31.8092 12.4368 32.6492 11.6085 32.6492 10.5702C32.6492 9.54351 31.8209 8.70351 30.7826 8.70351H23.9458C23.9458 8.70351 14.8225 1.12017 14.6942 1.16684C14.5658 1.21351 10.7508 1.16684 10.7508 1.16684L16.3159 8.62184H10.1092L6.25919 4.98184H3.72754H3.73915Z'
      stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M17.3637 12.4252L12.8604 16.8002H15.6953L23.4887 12.4252' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M0.83374 21.0818H35.5654' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
  </svg>
)

const Bed = (props) => (
  <svg width='27' height='15' viewBox='0 0 27 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.333008 0.0666504V12.6667H26.268V6.30832H0.333008' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M7.28634 6.30829V5.33996C7.28634 4.06829 6.25968 3.04163 4.98801 3.04163H0.333008' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M24.7859 12.6667V14.6034H1.81421V12.6667' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
  </svg>
)

const Bath = (props) => (
  <svg width='26' height='21' viewBox='0 0 26 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20.3953 10.6283H25.622L23.6037 19.2383H2.68532L0.666992 10.6283H15.2387' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M5.45215 19.2383V20.7549' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M20.842 19.2383V20.7549' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M8.02965 0.466675H5.64965C4.37798 0.466675 3.35132 1.49334 3.35132 2.76501V10.6283' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M8.0271 2.25171V4.07171' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M8.0271 5.54163V7.37329' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M20.3932 9.00659H15.2366V14.9333H20.3932V9.00659Z' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M20.3953 9.00671H22.5069V10.43' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
  </svg>
)

const Car = (props) => (
  <svg width='27' height='21' viewBox='0 0 27 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.20972 20.685V1.16663L26.7414 7.33629' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M25.7764 7.10889V20.685' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M22.4184 18.9363H5.53003L6.35455 13.449H21.5939L22.4184 18.9363Z' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M19.692 13.449H8.26245L8.81687 9.72449H19.1376L19.692 13.449Z' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M10.4518 18.9365V20.6851H6.4856V18.9365' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M21.4834 18.9365V20.6851H17.5029V18.9365' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M7.46143 16.4771H10.8021' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
    <path d='M17.1404 16.4771H20.4811' stroke={ props.color } stroke-width='0.583333' stroke-miterlimit='10'/>
  </svg>
)

const DoubleQuote = (props) => (
  <svg width='28' height='24' viewBox='0 0 28 24' fill={ props.color } xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.2838 23.5357C20.4199 23.5357 18.9315 22.8263 17.8187 21.4075C16.7059 19.9609 16.1495 18.2221 16.1495 16.1912C16.1495 12.7972 16.9146 9.76483 18.4447 7.09411C20.0026 4.39557 22.2421 2.03086 25.1632 0L26.8324 2.46207C25.2745 3.65833 24.0087 4.92414 23.035 6.25951C22.0891 7.59487 21.6161 8.76331 21.6161 9.76483C21.6161 10.7107 21.9361 11.434 22.5759 11.9348C23.2158 12.4355 23.953 12.8946 24.7876 13.3119C25.6501 13.7292 26.4012 14.2717 27.0411 14.9393C27.6809 15.607 28.0008 16.5807 28.0008 17.8605C28.0008 19.474 27.4584 20.8233 26.3734 21.9083C25.2884 22.9932 23.9252 23.5357 22.2838 23.5357ZM6.09258 23.5357C4.25646 23.5357 2.782 22.8263 1.6692 21.4075C0.5564 19.9609 0 18.2221 0 16.1912C0 12.7972 0.76505 9.76483 2.29515 7.09411C3.82525 4.39557 6.05085 2.03086 8.97195 0L10.6412 2.46207C9.08323 3.65833 7.81742 4.92414 6.84372 6.25951C5.87002 7.59487 5.38317 8.76331 5.38317 9.76483C5.38317 10.7107 5.7031 11.434 6.34296 11.9348C7.01064 12.4355 7.76178 12.8946 8.59638 13.3119C9.43098 13.7292 10.1682 14.2717 10.8081 14.9393C11.4758 15.607 11.8096 16.5807 11.8096 17.8605C11.8096 19.474 11.2671 20.8233 10.1821 21.9083C9.09715 22.9932 7.73396 23.5357 6.09258 23.5357Z'
      fill={ props.color }/>
  </svg>
)

const Marker = (props) => (
  <svg width='20' height='31' viewBox='0 0 20 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_757_1101)'>
      <path d='M18.915 10.145C18.915 5.03 14.76 0.875 9.645 0.875C4.53 0.875 0.375 5.03 0.375 10.145C0.375 19.415 9.645 30.395 9.645 30.395C9.645 30.395 18.915 19.415 18.915 10.145Z'
            stroke={ props.color }
            stroke-width='0.75' stroke-miterlimit='10'/>
      <path
        d='M10.0192 14.1951C12.198 14.1951 13.9642 12.4288 13.9642 10.2501C13.9642 8.07129 12.198 6.30505 10.0192 6.30505C7.84046 6.30505 6.07422 8.07129 6.07422 10.2501C6.07422 12.4288 7.84046 14.1951 10.0192 14.1951Z'
        stroke={ props.color } stroke-width='0.75' stroke-miterlimit='10'/>
    </g>
    <defs>
      <clipPath id='clip0_757_1101'>
        <rect width='19.29' height='30.465' fill={ props.color } transform='translate(0 0.5)'/>
      </clipPath>
    </defs>
  </svg>
)

const MediumArrow = (props) => (
  <svg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4 14H36.5M36.5 14L31.3571 19M36.5 14L31.3571 9' stroke={ props.color } stroke-width='0.8'/>
  </svg>
)

const CirclePlay = (props) => (
  <svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.1669 82.5842C43.9875 82.5842 43.8059 82.5405 43.6419 82.453C43.2766 82.2583 43.0469 81.8777 43.0469 81.4642V35.2028C43.0469 34.7893 43.2744 34.4087 43.6419 34.214C44.0072 34.0193 44.4512 34.0434 44.7947 34.2731L79.1277 57.4041C79.4362 57.6119 79.6221 57.9597 79.6221 58.3338C79.6221 58.7057 79.4362 59.0535 79.1277 59.2635L44.793 82.3946C44.6049 82.5214 44.3861 82.5849 44.1674 82.5849L44.1669 82.5842ZM45.2869 37.3067V79.3597L76.494 58.3332L45.2869 37.3067Z" fill="white"/>
    <circle cx="58" cy="58" r="57" stroke="white" stroke-width="2"/>
  </svg>
)

class Play extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.786865 0.0180664L6.02367 2.99679L11.2131 6.02367L6.02367 9.00303L0.786865 12.0299V0.0180664Z' fill={ color || 'white' }/>
      </svg>
    )
  }
}

class Sound extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 5.11198H2V12.78H0V5.11198Z' fill={ color }/>
        <path d='M5 2.55597H7V12.78H5V2.55597Z' fill={ color }/>
        <path d='M10 0H12V12.78H10V0Z' fill={ color }/>
      </svg>
    )
  }
}

class ScrollArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width='12' height='52' viewBox='0 0 12 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0.669193 44.8885L5.15714 49.4258L5.15714 0H6.10298L6.10298 49.4258L10.5909 44.8885L11.26 45.5649L5.63 51.2568L0 45.5649L0.669193 44.8885Z'
              fill={ color }/>
      </svg>
    )
  }
}

class Search extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.4482 14.1206L14.6206 14.9482L10.3325 10.7101C9.30121 11.549 7.98547 12.0517 6.55176 12.0517C3.23819 12.0517 0.551758 9.3653 0.551758 6.05173C0.551758 2.73816 3.23819 0.0517273 6.55176 0.0517273C9.86533 0.0517273 12.5518 2.73816 12.5518 6.05173C12.5518 7.48544 12.0491 8.80118 11.2102 9.83243L15.4482 14.1206ZM6.55163 10.8103C9.17988 10.8103 11.3103 8.67989 11.3103 6.05165C11.3103 3.4234 9.17988 1.29302 6.55163 1.29302C3.92338 1.29302 1.79301 3.4234 1.79301 6.05165C1.79301 8.67989 3.92338 10.8103 6.55163 10.8103Z'
          fill={ color }/>
      </svg>
    )
  }
}

const Projects = () => (
  <svg width='95' height='97' viewBox='0 0 95 97' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path class='rect' fill-rule='evenodd' clip-rule='evenodd'
          d='M11.45 17.6392H47.0855H51.4982H87.1337V96.329H81.9592C81.5135 86.5149 74.9083 78.7252 66.824 78.7252C58.7383 78.7252 52.1331 86.5149 51.6889 96.329H51.4982H47.0855H46.3237C45.878 86.5149 39.2728 78.7252 31.1885 78.7252C23.1027 78.7252 16.4975 86.5149 16.0533 96.329H11.45V70.9794V17.6392Z'
          fill='#FFFFFF'></path>
    <path d='M27.475 45.5614H19.8725L19.8742 35.5186C19.8742 32.9359 21.5759 30.8406 23.6746 30.8406C25.7733 30.8406 27.475 32.9342 27.475 35.5186V45.5614Z' fill='#e9e5db'></path>
    <path d='M45.0557 45.5614H37.4532L37.4549 35.5186C37.4549 32.9359 39.1566 30.8406 41.2553 30.8406C43.3539 30.8406 45.0557 32.9342 45.0557 35.5186V45.5614Z' fill='#e9e5db'></path>
    <path d='M62.6365 45.5614H55.034L55.0357 35.5186C55.0357 32.9359 56.7374 30.8406 58.8361 30.8406C60.9348 30.8406 62.6365 32.9342 62.6365 35.5186V45.5614Z' fill='#e9e5db'></path>
    <path d='M80.2172 45.5614H72.6147L72.6164 35.5186C72.6164 32.9359 74.3181 30.8406 76.4168 30.8406C78.5154 30.8406 80.2172 32.9342 80.2172 35.5186V45.5614Z' fill='#e9e5db'></path>
    <path d='M27.475 67.4096H19.8725L19.8742 57.3668C19.8742 54.784 21.5759 52.6887 23.6746 52.6887C25.7733 52.6887 27.475 54.7824 27.475 57.3668V67.4096Z' fill='#e9e5db'></path>
    <path d='M45.0557 67.4096H37.4532L37.4549 57.3668C37.4549 54.784 39.1566 52.6887 41.2553 52.6887C43.3539 52.6887 45.0557 54.7824 45.0557 57.3668V67.4096Z' fill='#e9e5db'></path>
    <path d='M62.6365 67.4096H55.034L55.0357 57.3668C55.0357 54.784 56.7374 52.6887 58.8361 52.6887C60.9348 52.6887 62.6365 54.7824 62.6365 57.3668V67.4096Z' fill='#e9e5db'></path>
    <path d='M80.2172 67.4096H72.6147L72.6164 57.3668C72.6164 54.784 74.3181 52.6887 76.4168 52.6887C78.5154 52.6887 80.2172 54.7824 80.2172 57.3668V67.4096Z' fill='#e9e5db'></path>
    <rect class='rect' x='0.866455' width='42.7638' height='28.5092' fill='#FFFFFF'></rect>
    <path d='M16.7841 21.8481H9.1816L9.18327 11.8052C9.18327 9.22252 10.885 7.1272 12.9837 7.1272C15.0823 7.1272 16.7841 9.22085 16.7841 11.8052V21.8481Z' fill='#e9e5db'></path>
    <path d='M34.3646 21.8481H26.7622L26.7638 11.8052C26.7638 9.22252 28.4656 7.1272 30.5642 7.1272C32.6629 7.1272 34.3646 9.22085 34.3646 11.8052V21.8481Z' fill='#e9e5db'></path>
  </svg>
)

export {
  Logo,
  Icon,
  Arrow,
  LongArrow,
  Play,
  Sound,
  ScrollArrow,
  Search,
  Marker,
  ShortArrowLeft,
  DoubleQuote,
  HouseLogo,
  Projects,
  DownArrow,
  CreateLogo,
  ShortArrow,
  MediumArrow,
  LandSize,
  AirPort,
  Bath,
  Car,
  Bed,
  CirclePlay,
  SearchIcon,
  ThinDownArrow,
  Download
}