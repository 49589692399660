module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"rootMargin":"0px 0px 0px 0px"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Create Property Group","short_name":"Create Property Group","start_url":"/","background_color":"#5D6144","theme_color":"#383D2F","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"680f5001c0399f3a8375a585c7a8d626"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://create-property.com.au","stripQueryString":true},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/gatsby-browser.js'),
      options: {"plugins":[],"disableLiveReload":true,"datoCmsFullAccessApiKey":"a5d4031466b0a3bbfc3e5402756658","datoCmsUrl":"https://create-property-group.admin.datocms.com","draftsUrl":"https://createpg-drafts.netlify.app","productionUrl":"https://create-property.com.au","site":{"name":"Create Property Group","defaultMetaDescription":"Your home, your legacy","facebookPageUrl":"https://www.facebook.com/CreatePropertyGroupAU","twitterAccount":"@createpg","primaryColor":"#383D2F","lightColor":"#EFEAE0","accentColor":"#383D2F","darkColor":"#5D6144","icon":"src/assets/images/favicon.svg"},"email":{"host":"ventraip.email","port":465,"username":"noreply@mailer.atollon.com.au","password":"dvr-mka6FJF!nat!jaf","sender":"noreply@mailer.atollon.com.au"},"connectors":[{"id":"rex","type":"real-estate","data":{"provider":"rex","email":"nick@atollon.com.au","password":"BingoBango2018!","accountId":1121}}],"locales":[],"admins":[],"models":[{"name":"Pages","apiKey":"page","apiKeyMultiple":"pages","type":"page","hasBlocks":true,"isDefaultPageType":true},{"name":"Case Studies","apiKey":"caseStudy","apiKeyMultiple":"caseStudies","type":"page","hasBlocks":true,"isDefaultPageType":false},{"name":"Header","apiKey":"header_menu_item","apiKeyMultiple":"header_menu_items","type":"menu"},{"name":"Footer","apiKey":"footer_menu_item","apiKeyMultiple":"footer_menu_items","type":"menu"},{"name":"Site Options","apiKey":"site_option_page","apiKeyMultiple":"site_option_pages","type":"site-options"},{"name":"Properties","apiKey":"property","apiKeyMultiple":"properties","type":"property","connectors":["rex"]},{"name":"Agents","apiKey":"agent","apiKeyMultiple":"agents","type":"agent","connectors":["rex"],"permalink":true},{"name":"Inspections","apiKey":"inspection","apiKeyMultiple":"inspections","type":"inspection","timezone":"Australia/Sydney","connectors":["rex"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PNHHVQS6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
