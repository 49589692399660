import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { hierarchicalMenu, Link } from '../../plugins/gatsby-plugin-atollon'

const Footer = () => {

  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
    query DesktopFooterData {
      au: allDatoCmsFooterMenuItem(locale: "en-AU") {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
        }
      }
    }
  `)
  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)

  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__left'>
          <Link to='tel:1800695263'>1800 695 263</Link>
          <Link to='mailto:hello@create-property.com.au'>hello@create-property.com.au</Link>
          <div className='footer__cols'>
            <div className='footer__col'>
              <p>2301/4 Sterling Circuit <br/> Camperdown NSW 2050</p>
            </div>
            <div className='footer__col'>
              <Link to='https://www.linkedin.com/company/create-property/about/' target='_blank' rel='noopener noreferrer'>LinkedIn</Link><Link to='https://www.instagram.com/create.property/' target='_blank' rel='noopener noreferrer'>Instagram</Link>
            </div>
          </div>
        </div>

        <div className='footer__right'>
          <div className='footer__reserved'>
            <p>Create Property 2023.</p>
            <p>All rights reserved.</p>
          </div>

          <div className='footer__links'>
            <Link to='/privacy-policy/'>Privacy</Link>
            <Link to='/terms-of-use/'>Terms of Use</Link>
            <Link to='https://atollon.com.au/' target='_blank' rel='noopener noreferrer'>Design by Atollon</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
