import React from 'react'
import { Logo, CreateLogo } from './icons'
import { graphql, useStaticQuery } from 'gatsby'
import { Asset, hierarchicalMenu, Link } from '../../plugins/gatsby-plugin-atollon'

const mouseEvent = (items) => {
  // Convert items to an array if it's not already
  const elements = Array.isArray(items) ? items : Array.from(items)

  elements.forEach((el) => {
    el.addEventListener('mouseenter', () => {
      elements.forEach((item) => {
        if (item !== el) item.classList.add('blur')
      })
    })
  })

  elements.forEach((el) => {
    el.addEventListener('mouseleave', () => {
      elements.forEach((item) => {
        item.classList.remove('blur')
      })
    })
  })
}

const Header = ({ pageContext }) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [prevScroll, setPrevScroll] = React.useState(0)
  const [scrollY, setScrollY] = React.useState(0)
  const [hoveredImage, setHoveredImage] = React.useState(0)
  const [rolloverMenu, setRolloverMenu] = React.useState({
    subActive: false,
    menu: [],
    initial: false,
  })

  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
    query DesktopHeaderData {
      siteOptions: datoCmsSiteOptionPage {
        menuImages {
          ...InternalAssetProps
        }
      }
      au: allDatoCmsHeaderMenuItem(locale: "en-AU") {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
        }
      }
    }
  `)

  let isScrolling

  const onScroll = (e) => {
    let scr, hhScrolled, prvScroll

    const curScroll = window.scrollY || document.scrollTop
    scr = curScroll > 1
    setScrollY(curScroll)

    if (Math.abs(prevScroll - curScroll) <= window.innerHeight * 0.35) return

    scr = curScroll > 1

    prvScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setPrevScroll(prvScroll)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollDetect, false)
    return () => {
      window.removeEventListener('scroll', scrollDetect, false)
    }
  }, [])

  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)
  const menuImages = staticData.siteOptions.menuImages

  let props = {
    onClick: () => setOffCanvas(false),
    activeClassName: 'active',
  }

  const menuRollover = (key) => {
    let { subActive, menu, initial } = rolloverMenu
    subActive = false
    initial = false

    if (rolloverMenu.menu[key]) {
      setRolloverMenu({
        subActive: false,
        menu: [],
        initial: false,
      })
      return
    }

    if (menu[key]) {
      menu[key] = true
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }

    if (key < 3) subActive = true

    setRolloverMenu({ initial, menu, subActive })
  }

  const menuHoverImage = (key) => {
    setHoveredImage(key)
  }

  // gatsby check if typeof document exists
  mouseEvent(typeof document !== 'undefined' && document.querySelectorAll('.off-canvas__menu .menu-item'))
  mouseEvent(typeof document !== 'undefined' && document.querySelectorAll('.off-canvas__sub-menu li'))

  let headerClass = 'header'
  if (scrolled) headerClass += ' header--scrolled'

  return (
    <>
      <header className={ headerClass }>
        <div className='header__inner'>
          <Link className='header__logo' to='/' {...props}><Logo/></Link>
          <Link className='header__wordmark' to='/' {...props}><CreateLogo color='#EFEAE0'/></Link>
          <nav className='header__nav'>
            <Link to='tel:1800695263'>1800 695 263</Link>
            <button onClick={ () => {
              setHoveredImage(0)
              setOffCanvas(prev => !prev)
              setRolloverMenu({
                subActive: false,
                menu: [],
                initial: false,
              })
            } } className='header__menu'>
              { offCanvas ? 'Close' : 'Menu' }
            </button>
            <button onClick={ () => setOffCanvas(prev => !prev) } className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              { offCanvas ? <span>Close</span> : <span className='lines'></span> }
            </button>
            <button onClick={ () => window.history.back() } className='contact-only'>Close</button>
          </nav>
        </div>
      </header>
      <div className={ `off-canvas ${ offCanvas ? 'active' : '' } ${ rolloverMenu.menu[1] ? 'off-canvas--services' : '' } ${ rolloverMenu.menu[2] ? 'off-canvas--properties' : '' }` }>
        <div className='off-canvas__inner'>
          <div className='off-canvas__image'>
            {
              menuImages.length > 0 && menuImages?.map((el, i) => (
                <div key={ i } className={ `off-canvas__image-item ${ hoveredImage === i ? 'active' : '' }` }>
                  <Asset asset={ el }/>
                </div>
              ))
            }
          </div>
          <nav className='off-canvas__nav'>
            <div className='off-canvas__menu'>
              <ul>
                <li className={ `menu-item` } onMouseEnter={ () => menuHoverImage(0) }><Link to='/about/' { ...props }>About</Link></li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(1) }>
                  <button onClick={ () => menuRollover(1) } className={ `${ rolloverMenu.menu[1] ? 'active' : '' }` } to='/'>Services</button>
                  <div className={ `off-canvas__sub-menu ${ rolloverMenu.menu[1] ? 'active' : '' }` }>
                    <ul>
                      <li><Link to='/vendor-agent/' { ...props }>Vendor Agent</Link></li>
                      <li><Link to='/property-acquisition/' { ...props }>Property Acquisition</Link></li>
                      <li><Link to='/property-research-advisory/' { ...props }>Property Research Advisory</Link></li>
                      <li><Link to='/property-management/' { ...props }>Property Management</Link></li>
                    </ul>
                  </div>
                </li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(2) }><Link to='/team/' { ...props }>Team</Link></li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(3) }>
                  <button onClick={ () => menuRollover(2) } className={ `${ rolloverMenu.menu[2] ? 'active' : '' }` }>Property</button>
                  <div className={ `off-canvas__sub-menu ${ rolloverMenu.menu[2] ? 'active' : '' }` }>
                    <ul>
                      <li><Link to='/for-sale/' { ...props }>For Sale</Link></li>
                      <li><Link to='/for-lease/' { ...props }>For Lease</Link></li>
                      <li><Link to='/sold/' { ...props }>Sold</Link></li>
                    </ul>
                  </div>
                </li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(4) }><Link to='/case-studies/' { ...props }>Case Studies</Link></li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(5) }><Link to='/appraisals/' { ...props }>Appraisals</Link></li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(6) }><Link to='/faq' { ...props }>FAQs</Link></li>
                <li className='menu-item' onMouseEnter={ () => menuHoverImage(7) }><Link to='/contact/' { ...props }>Contact</Link></li>
              </ul>
            </div>

            <div className={ `off-canvas__contact ${ rolloverMenu.subActive ? 'active' : '' }` }>
              <Link to='tel:1800695263'>1800 695 263</Link>
              <Link to='/'>hello@create-property.com.au</Link>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Header
